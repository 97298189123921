export enum DateFormat {
	ISO = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
	ISODATE = 'yyyy-MM-dd',
	ISODATETIME_NO_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ss",
	DATE = 'dd/MM/yyyy',
	TIME = 'HH:mm:ss',
	DATETIME = 'dd/MM/yyyy HH:mm:ss',
	DATETIME_NO_TIMEZONE = "dd/MM/yyyy'T'HH:mm:ss",
	ISODATETIME_MAXHOURS = "yyyy-MM-dd'T'23:59:59",
	DATE_HH_MM = 'dd/MM/yyyy HH:mm',
	ISODATETIME_HH_MM_SS = 'yyyy-MM-dd HH:mm:ss',
	ISODATETIME_HH_MM = "yyyy-MM-dd'T'HH:mm",
	DAY_MONTHO_YEAR = 'DD [de] MMMM',
	DAY = 'dd',
	MONTH = 'MM',
}
