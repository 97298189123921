// const  enum EnumTipoFecha {
//   ETA = "fchEstimadaLlegadaDestino",
//   LLEGADA = "fchLlegada",
//   ETD = "fchEmbarque",
//   // ROUTING = "fchRouting",
//   HBL = "fchRouting", //_>remplazr por ROUTING
//   SOLICITUD = 'solicitud',
//   TERMINO_DESCARGA = 'terminoDescarga'
// }

// export const enum EnumTipoFecha {
// 	ROUTING = 'fchRouting', //1
// 	ROUTING_LABEL = 'ROUTING',

// 	ETA = 'fchEstimadaLlegadaDestino', //2
// 	ETA_LABEL = 'ESTIM. LLEGADA',

// 	LLEGADA = 'fchLlegada', //3
// 	LLEGADA_LABEL = 'LLEGADA',

// 	ETD = 'fchEmbarque', //4
// 	ETD_LABEL = 'ESTIM. EMBARQUE',

// 	SOLICITUD = 'solicitud',
// 	SOLICITUD_LABEL = 'SOLICITUD',

// 	TERMINO_DESCARGA = 'terminoDescarga',
// 	TERMINO_DESCARGA_LABEL = 'TERMINO DESCARGA',
// }
export class EnumTipoFecha {
	/**
	 * @description ROUTING
	 */
	static get ROU() {
		return {
			code: 'ROU',
			value: 'fchRouting',
			label: 'ROU - ROUTING',
		};
	}
	/**
	 * @description ESTIMADA EMBARQUE
	 */
	static get ETD() {
		return {
			code: 'ETD',
			value: 'fchEstimadaEmbarque',
			label: 'ETD - ESTIMADA EMBARQUE',
		};
	}
	/**
	 * @description EMBARQUE
	 */
	static get ATD() {
		return {
			code: 'ATD',
			value: 'fchEmbarque',
			label: 'ATD - EMBARQUE',
		};
	}
	/**
	 * @description ESTIMADA LLEGADA DESTINO
	 */
	static get ETA() {
		return {
			code: 'ETA',
			value: 'fchEstimadaLlegadaDestino',
			label: 'ETA - ESTIMADA LLEGADA',
		};
	}
	/**
	 * @description LLEGADA
	 */
	static get ATA() {
		return {
			code: 'ATA',
			value: 'fchLlegada',
			label: 'ATA - LLEGADA',
		};
	}

	static get SOLICITUD() {
		return {
			code: '',
			value: 'solicitud',
			label: 'SOLICITUD',
		};
	}

	static get TERMINO_DESCARGA() {
		return {
			code: '',
			value: 'terminoDescarga',
			label: 'TERMINO DESCARGA',
		};
	}
}
